import React, {useState, useEffect} from "react";
import { HiHome, HiCog, HiCreditCard, HiOutlineArrowLeft, HiLogout, HiMenu } from 'react-icons/hi';
import { FaBell, FaRegBell } from 'react-icons/fa'
import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";
import {useGetMeQuery, usePostLogoutMutation} from "../../rtk-api/auth";
import {useDispatch} from "react-redux";
import {apiSlice} from "../../rtk-api/apiSlice";
import {useGeneralStore} from "../../stores/general";
import {useResendConfirmationEmailMutation} from "../../rtk-api/user";
import {useGetAllNotificationsQuery, usePostNotificationGlobalReadMutation, usePostNotificationReadMutation} from "../../rtk-api/notifications";

function Header() {
	const dispatch = useDispatch();
	
	const setShowGlobalLoading = useGeneralStore((state) => state.setShowGlobalLoading)
	const {data:notifications, isSuccess} = useGetAllNotificationsQuery()
	const [postMarkNotificationRead, {isLoading: isResendInviteLoading}] = usePostNotificationReadMutation()
	const [postNotificationGlobalRead] = usePostNotificationGlobalReadMutation()
	const [postLogout] = usePostLogoutMutation()
	const [postResendEmailConfirmation] = useResendConfirmationEmailMutation()
	const [readNotifications, setReadNotifications] = useState([])
	const {data: meInfo} = useGetMeQuery();
	const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
	const [announcementsOpened, setAnnouncementsOpened] = useState(false);
	const [newAnnouncements, setNewAnnouncements] = useState(false);
	const [sendText, setSendText] = useState('Send it again');
	
	useEffect(() => {
		setNewAnnouncements(notifications?.global_unread)
	}, [isSuccess]);

	function toggleMobileMenu() {
		setMobileMenuOpened(!mobileMenuOpened);
	}
	
	async function toggleAnnoucements() {
		setAnnouncementsOpened(!announcementsOpened);
		setNewAnnouncements(false)
		
		if (notifications?.global_unread) {
			await postNotificationGlobalRead()
		}
	}
	
	function handleLogout() {
		setMobileMenuOpened(false);
		setShowGlobalLoading(true)
		postLogout({
			data: {}
		}).then(() => {
			dispatch(apiSlice.util.resetApiState())
			window.location.replace(process.env.NEXT_PUBLIC_AUTH_URL + '/login');
		})
	}
	
	async function handleResendEmailConfirmation() {
		if (sendText != 'Resent!') {
			await postResendEmailConfirmation({
				data: {}
			}).then(() => {
				setSendText('Resent!')
			})
		}
	}
	
	async function markRead(e, notification) {
		window.open(notification.full_url, '_blank');
		await postMarkNotificationRead({
			id: notification.id
		})
		setReadNotifications(readNotifications => [...readNotifications, notification.id]);
	}
	
	return (
		<>
			{announcementsOpened &&
				<div className="absolute w-full h-full z-50" onClick={() => toggleAnnoucements()}></div>
			}
			<header className="block shadow-sm mb-2 bg-white py-3 mb-5 w-full relative">
				{meInfo?.data.status === 2 &&
					<div className="block p-2 mb-2 -mt-3 text-center text-sm bg-yellow-100 text-yellow-900">
						<span>
							Please verify your email address by clicking the link we sent to your inbox (or spam folder).
							<span className="block sm:inline-block sm:ml-2">
								<span className="font-bold cursor-pointer" onClick={handleResendEmailConfirmation}>{sendText}</span>
								&nbsp;·&nbsp;
								<Link href="/settings"><span className="font-bold cursor-pointer">Change email address</span></Link>
							</span>
						</span>
					</div>
				}
				
				<div className="md:container m-auto flex justify-between items-center">
					<Link href="/" legacyBehavior>
						<div className="flex cursor-pointer w-48 h-auto ml-2">
							<Image
								src="/images/pagename-logo-color-and-black.png"
								height={32}
								width={175}
								style={{alignSelf: 'center'}}
								alt="PageName Logo"
							/>
						</div>
					</Link>
					
					<div className="flex items-center">
						<div className="md:relative">
							
							{ newAnnouncements &&
								<div className="relative">
									<span className="absolute flex h-2 w-2 ml-3 -top-1">
							  			<span className="inline-flex rounded-full h-2 w-2 bg-red-500"></span>
									</span>
								</div>
							}
							
							{ announcementsOpened ?
								<FaRegBell className="mr-4 cursor-pointer" onClick={toggleAnnoucements} />
								:
								<FaBell className="mr-4 cursor-pointer" onClick={toggleAnnoucements} />
							}
							
							{ announcementsOpened &&
								<>
									<div className="absolute top-10 right-0 sm:right-0 transform  bg-white drop-shadow-md w-80 z-50 border-2 border-gray-50">
										
										<h2 className="p-2 text-center text-lg border-gray-50 border-b-[1px] mb-2">Latest Updates</h2>
										
										{notifications.length == 0 ?
											<div className="w-full py-10 text-center text-gray-300 text-sm">Check back later for new updates!</div>
											:
											<ul className="">
												{notifications?.updates?.map((notification) =>
													<li
														className={classNames(
															{'border-l-green-500': notification.unread && !readNotifications.includes(notification.id)},
															{'border-l-8': notification.unread && !readNotifications.includes(notification.id)},
															'px-4',
															'mb-3',
															'hover:bg-gray-100'
														)}
														key={notification.id}
														onClick={e => markRead(e, notification)}
													>
														<a href={notification.link ?? '#'} className="dropdown-item my-2 new-notification notification-item">
															<div className="text-md mb-1 font-semibold">{notification.title}</div>
															<div className="text-sm">{notification.preview_text}</div>
														</a>
													</li>
												)}
											</ul>
										}
										<Link href={process.env.NEXT_PUBLIC_UPDATES_URL} className="p-2 text-sm text-gray-500 text-center block border-gray-50 border-t-[1px]">
											View all updates
										</Link>
									</div>
								</>
							}
						</div>
						
						{ meInfo?.data.plan.type == 'free' &&
							<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/pricing'}><button className="btn upgrade-color block py-1 mr-4">Upgrade</button></Link>
						}
						<HiMenu
							onClick={() => toggleMobileMenu()}
							className="w-5 h-5 cursor-pointer mr-5"
						/>
					</div>
				</div>
			</header>
			
			{mobileMenuOpened &&
				<>
					<div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity z-50" onClick={() => toggleMobileMenu()}>
					</div>
					<div className="fixed top-0 bottom-0 right-0 bg-blue-900 text-white z-50 w-72 text-xl" style={{ zIndex: 100}}>
						<div className="text-right">
							<button className="text-4xl mr-3" type="button" onClick={() => toggleMobileMenu()}>×</button>
						</div>
						<ul className="navbar-nav mr-auto mt-5">
							<li className="mb-1">
								<Link href="/" legacyBehavior>
									<a className="block flex items-center px-5 py-3">
										<HiHome className="w-6 h-6 mr-3" />
										<span>Dashboard</span>
									</a>
								</Link>
							</li>
							<li className="mb-1">
								<Link href="/settings" legacyBehavior>
									<a className="block flex items-center px-5 py-3">
										<HiCog className="w-6 h-6 mr-3" />
										<span>Settings</span>
									</a>
								</Link>
							</li>
							<li className="mb-1">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/settings/billing'} legacyBehavior>
									<a className="block flex items-center px-5 py-3">
										<HiCreditCard className="w-6 h-6 mr-3" />
										<span>Billing</span>
									</a>
								</Link>
							</li>
							<li className="mb-1">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL} legacyBehavior>
									<a className="block flex items-center px-5 py-3" href="#">
										<HiOutlineArrowLeft className="w-6 h-6 mr-3" />
										<span>Back to PageName</span>
									</a>
								</Link>
							</li>
							<li className="mb-1">
								<div
									className="cursor-pointer block flex items-center px-5 py-3"
									onClick={handleLogout}
								>
									<HiLogout className="w-6 h-6 mr-3"/>
									<span>Logout</span>
								</div>
							</li>
						</ul>
					</div>
				</>
			}
		</>
	
	);
}

export default Header;
