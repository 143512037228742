import Head from 'next/head';
import Header from "./inc/header";
import Spinner from "./Spinner";
import {useGeneralStore} from "../stores/general";
import GlobalMessage from "./GlobalMessage";
import {useGetMeCheckQuery} from "../rtk-api/auth";

export const siteTitle = 'Next.js Sample Website';

export default function Layout({ children, title }) {
	
	const showGlobalLoading = useGeneralStore((state) => state.showGlobalLoading)
	const globalMessage = useGeneralStore((state) => state.globalMessage)
	
	return (
		<>
			<Head>
				<link rel="icon" href="/favicon.ico"/>
				{/*<meta*/}
				{/*	name="description"*/}
				{/*	content="Learn how to build a personal website using Next.js"*/}
				{/*/>*/}
				{/*<meta*/}
				{/*	property="og:image"*/}
				{/*	content={`https://og-image.vercel.app/${encodeURI(*/}
				{/*		siteTitle,*/}
				{/*	)}.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg`}*/}
				{/*/>*/}
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"></meta>
				{/*<meta name="og:title" content={siteTitle}/>*/}
				{/*<meta name="twitter:card" content="summary_large_image"/>*/}
				<title>{title}</title>
			</Head>
			
			<main className="m-auto ">
				<Header/>
				
				<Spinner show={showGlobalLoading} full size={500}/>
				
				{Object.keys(globalMessage).length != 0 &&
					<GlobalMessage
						{...globalMessage}
					/>
				}
				
				{!showGlobalLoading &&
					children
				}
			</main>
		
		</>
	);
}
