import React from 'react';
import classNames from "classnames";
import Image from "next/image"

function Spinner(props) {
	
	function handleShow() {
		if (props.show === false) {
			return false
		}
		
		return true
	}
	
	return (
		<>
			{handleShow() &&
				<div
					className={classNames(
						{'inline-block':!props.full},
						{'absolute':!props.full},
						{'-top-2':!props.full},
						{'-ml-2':!props.full},
						{'flex': props.full},
						{'w-full': props.full},
						{'items-center': props.full},
						{'justify-center': props.full},
					)}
				>
					<Image
						src="/images/spinner-200px.svg"
						alt="Loading..."
						height={props.size ?? 48}
						width={props.size ?? 48}
						className={classNames(
							'spinner',
							props.className
						)}
					/>
				</div>
			}
		</>
	);
}

export default Spinner;
