"use client"

import React, {useState, useEffect} from 'react';
import classNames from "classnames";
import {useGeneralStore} from "../stores/general";

function GlobalMessage(props) {
	
	const [showMessage, setShowMessage] = useState(true);
	const setGlobalMessage = useGeneralStore((state) => state.setGlobalMessage)
	
	
	useEffect(() => {
		setTimeout(function () {
			setShowMessage(false);
			setGlobalMessage({})
		}, 3000);
	}, []);
	
	function getText() {
		if (props.error && props.message === undefined) {
			return 'There was an issue and we have been alerted. Please try again later.'
		}
		return props.message
	}
	
	return (
		<>
			{showMessage &&
				<div className={classNames(
					'global-message',
					{'error': props.error},
					{'warning': props.warning},
					{'success': props.success}
				)}>
					{getText()}
				</div>
			}
		</>
		
	);
}

export default GlobalMessage;
