import { apiSlice } from "./apiSlice"
import {deepmerge} from "../helpers/utils";

const apiWithTag = apiSlice.enhanceEndpoints({addTagTypes: ['Notifications']})

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getAllNotifications: build.query({
			query: () => ({url: '/notifications', method: 'get' })
		}),
		postNotificationRead: build.mutation({
			query: postData => ({
				url: '/notifications/read',
				method: 'POST',
				body: postData
			}),
			invalidatesTags: ['Notifications']
		}),
		postNotificationGlobalRead: build.mutation({
			query: () => ({
				url: '/notifications/global-seen',
				method: 'POST',
			}),
			invalidatesTags: ['Notifications']
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetAllNotificationsQuery,
	usePostNotificationReadMutation,
	usePostNotificationGlobalReadMutation,
	util: { getRunningQueriesThunk, resetApiState, getState }
	
} = extendedApi

export const {
	getAllNotifications,
	postNotificationRead,
	postNotificationGlobalRead
} = extendedApi.endpoints;
