import { apiSlice } from "./apiSlice"

const extendedApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		deleteUser: build.mutation({
			query: data => ({
				url: '/settings/user/delete',
				method: 'DELETE',
				body: data.data
				
			})
		}),
		resendConfirmationEmail: build.mutation({
			query: data => ({
				url: '/confirm/resend',
				method: 'POST',
			})
		}),
		confirmEmail: build.mutation({
			query: data => ({
				url: '/settings/user/delete',
				method: 'DELETE',
				body: data.data
				
			})
		})
	}),
	overrideExisting: false,
})

export const { useDeleteUserMutation, useResendConfirmationEmailMutation, useConfirmEmailMutation } = extendedApi
