import {useGeneralStore} from "../stores/general";

export const isLocal = () => {
	return process.env.NEXT_PUBLIC_RELEASE_STAGE === undefined
			|| process.env.NEXT_PUBLIC_RELEASE_STAGE === 'local'
}

const PhoneIframe = () => {
	return document.getElementById('phone').contentWindow
}

const updatePhone = (func) => {
	PhoneIframe().postMessage([func], process.env.NEXT_PUBLIC_PHONE_BASE_URL)
}

export const refreshPhone = () => {
	var iframe = document.getElementById('phone');
	iframe.src = iframe.src;
}

export const modifyStyle = (selector, rule, value, type = 'css') => {
	updatePhone({selector, type, rule, value})
}

export const modifyRemoveStyle = (selector, rule, value, type = 'removeStyle') => {
	updatePhone({selector, type, rule, value})
}

export const modifyText = (selector, value) => {
	updatePhone({selector, type: 'text', value})
}

export const modifyToggle = (selector, value) => {
	updatePhone({selector, value, type: 'toggle'})
}

export const modifyHref = (selector, value) => {
	updatePhone({selector, value, type: 'href'})
}

export const modifyRemoveClass = (selector, value) => {
	updatePhone({selector, value, type: 'removeClass'})
}

export const modifyAddClass = (selector, value) => {
	updatePhone({selector, value, type: 'addClass'})
}

export const modifyAnimate = (selector, value) => {
	value = 'animated infinite '+ value
	updatePhone({selector, value, type: 'addClass'})
}

export const modifyRemoveAnimation = (selector, value) => {
	value = 'animated infinite'
	updatePhone({selector, value, type: 'removeClass'})
}

export const truncate = (string, limit = 40) => {
	if (!string) {
		return string
	}
	
	if (string.length <= limit) {
		return string
	}
	return string.slice(0, limit) + "..."
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getSingleErrors(key, errors) {
	// if (errors === undefined) {
	// 	errors = [];
	// 	errors[key] = ['There was an issue and we are looking into this.']
	// }
	
	if (errors !== undefined) {
		if (errors._global != undefined) {
			errors[key] = errors._global
		}
		return errors[key]?.map((item, index) =>
			<div className="text-red-500 text-sm" key={index}>{item}</div>
		)
	}
}

export function showError(key, errors) {
	return getSingleErrors(key, errors)
}

export function buildErrorObject(httpStatus, errors) {
	switch(httpStatus) {
		case 500:
			return {_global: ['There was an issue and we have been alerted. Please try again later.']}
			break;
		case 429:
			return {_global: ['Too many requests. Please try again later.']}
			break;
		default:
			return errors;
	}
	// if (httpStatus === 500) {
	// 	return {
	// 		_global: ['There was an issue and we have been alerted. Please try again later.']
	// 	}
	// } else {
	// 	return errors
	// }
}

export function cardClass(isDisabled = false) {
	let className = 'card '
	
	if (isDisabled) {
		className += ' disabled'
	}
	return className
}

export function isOnFreePlan(userInfo) {
	return userInfo?.data.plan.type === 'free' ? true : false
}

export function capitlizeFirst(str) {
	// checks for null, undefined and empty string
	if (!str) return;
	return str.match("^[a-z]") ? str.charAt(0).toUpperCase() + str.substring(1) : str;
}

export function doesKeyExist(object) {

}

export const TypingDuration = 500

export const getCookie = (name) => {
	// Split cookie string and get all individual name=value pairs in an array
	var cookieArr = document.cookie.split(";");
	// Loop through the array elements
	for(var i = 0; i < cookieArr.length; i++) {
		var cookiePair = cookieArr[i].split("=");
		/* Removing whitespace at the beginning of the cookie name
		and compare it with the given string */
		if(name == cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}
	// Return null if not found
	return null;
}

export const deleteCookie = (name) => {
	document.cookie = name +'=; Path=/;  Domain='+process.env.NEXT_PUBLIC_DOMAIN+'; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure'
}

export function deepmerge() {
	const merge = function () {
		let target = arguments[0];
		for (let i = 1; i < arguments.length; i++) {
			let arr = arguments[i];
			for (let k in arr) {
				if (Array.isArray(arr[k])) {
					if (target[k] === undefined) {
						target[k] = [];
					}
					target[k] = [...new Set(target[k].concat(...arr[k]))];
				} else if (typeof arr[k] === "object") {
					if (target[k] === undefined) {
						target[k] = {};
					}
					target[k] = merge(target[k], arr[k]);
				} else {
					if (Array.isArray(target) && target.length === 0) {
						target = {};
					}
					target[k] = arr[k];
				}
			}
		}
		return target;
	};
	return merge(...arguments);
}

export  function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}


export const parseCookie = str =>
	str
		.split(';')
		.map(v => v.split('='))
		.reduce((acc, v) => {
			acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
			return acc;
		}, {});


export function createObjectFromDotString(dotString, value) {
	const obj = {};
	let current = obj;
	
	dotString.split('.').forEach((key, index, array) => {
		current[key] = index === array.length - 1 ? value : {};
		current = current[key];
	});
	
	return obj;
}

export function handleStatusCode(statusCode) {
	return {
		redirect: {
			permanent: false,
			destination: `/${statusCode}`
		}
	}
}

export function shortenNumber(num, digits = 1) {
	const lookup = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup.slice().reverse().find(function(item) {
		return num >= item.value;
	});
	return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
